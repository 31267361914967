import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { Logo } from './Logo';
import { TituloContainer } from './TituloContainer';

export function Header() {
  return (
    <Flex
      minH={{ base: '100px', md: '160px' }}
      bg="linear-gradient(to right, #00621d, #0ba33d 90%)"
      mb={{ base: '45px', md: '25px' }}
    >
      <Logo />
      <TituloContainer />
    </Flex>
  );
}
