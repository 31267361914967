import React from 'react';
import { Image } from '@chakra-ui/image';
import { Flex, Text } from '@chakra-ui/layout';
import imageLogo from '../../../../assets/logo-app.svg';

interface LogoProps {
  activated: boolean;
}

export function Logo({ activated }: LogoProps) {
  return (
    <Flex spacing="16px" width="100%" h="80px" align="center" justify="center">
      <Text
        fontSize="2xl"
        color="#14a64f"
        fontWeight="bold"
        textShadow="1px 1px #666"
      >
        {activated
          ? process.env.REACT_APP_SIGLA || 'SIGLA'
          : process.env.REACT_APP_SIGLA2}
      </Text>
      {activated && <Image height="45px" ml="20px" src={imageLogo} />}
    </Flex>
  );
}
