import { useMediaQuery } from '@chakra-ui/react';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import React from 'react';

export function TituloContainer() {
  const [maiorQue480px] = useMediaQuery('(min-width: 480px)');
  return (
    <Flex
      w={{ base: '100%', md: '70%' }}
      h={{ base: '45px', md: '50px' }}
      position="absolute"
      top={{ base: '100px', md: '135px' }}
      left={{ base: '0', md: '15%' }}
      direction="row"
      align="center"
      pl="15px"
      pr="50px"
      background="white"
      textAlign="center"
      fontSize="12px"
      borderBottomWidth="6px"
      borderBottomStyle="solid"
      borderBottomColor="coral"
      boxShadow="rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;"
    >
      <Box w="100%" ml="15px">
        <Heading
          as="h3"
          fontWeight={{ base: 400, md: 'normal' }}
          fontSize={{ base: '14px', md: '20px' }}
          lineHeight={{ base: '16px', md: '16px' }}
        >
          {process.env.REACT_APP_TITLE ||
            'ADICIONE O TÍTULO EM .ENV NA VARIÁVEL REACT_APP_TITLE'}
          {maiorQue480px && <span> - {process.env.REACT_APP_SIGLA || ''}</span>}
        </Heading>
      </Box>
    </Flex>
  );
}
