import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

const SignIn = lazy(
  () =>
    import(
      /* webpackChunkName: "signin" */
      '../pages/signin'
    ),
);

const FiFaGeral = lazy(
  () =>
    import(
      /* webpackChunkName: "fifaGeral" */
      '../pages/fifaGeral'
    ),
);

const Manutencao = lazy(
  () =>
    import(
      /* webpackChunkName: "manutencao" */
      '../pages/manutencao'
    ),
);

const Dashboard = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard" */
      '../pages/dashboard'
    ),
);

const Promocoes = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "promocoes" */
      '../pages/promocoes'
    ),
);

const CadastrarPromocao = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "cadastrarPromocoes" */
      '../pages/promocoes/cadastrar'
    ),
);

const EditarPromocao = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "editarPromocoes" */
      '../pages/promocoes/editar'
    ),
);

const EditarCandidato = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "editarCandidato" */
      '../pages/candidatos/editar'
    ),
);

const CadastrarCandidato = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "cadastrarCandidato" */
      '../pages/candidatos/cadastrar'
    ),
);

const CadastrarAvaliacao = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "cadastrarAvaliacao" */
      '../pages/avaliacoesOficiais/cadastrar'
    ),
);

const AvaliacoesOficiais = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "avaliacoesOficiais" */
      '../pages/avaliacoesOficiais'
    ),
);

const EditarAvaliacaoOficial = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "editarAvaliacao" */
      '../pages/avaliacoesOficiais/editar'
    ),
);

const CadastrarAvaliacaoFisica = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "cadastrarAvaliacaoFisica" */
      '../pages/avaliacoesFisicas/cadastrar'
    ),
);

const EditarAvaliacaoFisica = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "editarAvaliacaoFisica" */
      '../pages/avaliacoesFisicas/editar'
    ),
);

const CadastrarAvaliacaoFisicaIndividual = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "cadastrarAvaliacaoFisicaIndividual" */
      '../pages/avaliacoesFisicas/cadastrar/cadastroIndividual'
    ),
);

const AvaliacoesFisicas = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "avaliacoesFisicas" */
      '../pages/avaliacoesFisicas'
    ),
);

const FiFa = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "editarFiFa" */
      '../pages/fisfas/editar'
    ),
);

const ListaFichasOpm = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "listaFichasOpm" */
      '../pages/fisfasPoliciais'
    ),
);

const ListaFichasComandante = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "listaFichasComandante" */
      '../pages/fisfasComandante'
    ),
);

const CadastrarMembroCPO = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "cadastraMembroCPO" */
      '../pages/membrosCPO/cadastrar'
    ),
);

const ListaJulgamentosCPO = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "jusgamentosCPO" */
      '../pages/jugamentosCPO'
    ),
);

const EditarJulgamentosCPO = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "jusgamentosCPO-editar" */
      '../pages/jugamentosCPO/editar'
    ),
);

const ListarManuaisDocumentos = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "listar-manuais-docs" */
      '../pages/manuaisDocumentos'
    ),
);

const Routes: React.FC = () => {
  const ambiente = process.env.REACT_APP_ENV;
  return (
    <Switch>
      <Route
        path="/"
        exact
        component={ambiente === 'manutencao' ? Manutencao : SignIn}
      />
      <Route path="/home" component={Dashboard} isPrivate hasRole={['*']} />
      <Route
        path="/minhaficha"
        component={FiFa}
        isPrivate
        hasRole={['USUÁRIO PADRÃO']}
      />
      <Route
        path="/promocoes"
        component={Promocoes}
        isPrivate
        exact
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />
      <Route
        path="/promocoes/cadastrar"
        component={CadastrarPromocao}
        isPrivate
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />
      <Route
        path="/promocoes/editar/:id"
        component={EditarPromocao}
        isPrivate
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />

      <Route
        path="/candidatos/editar/:id/:tipo"
        component={EditarCandidato}
        isPrivate
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />
      <Route
        path="/avaliacoes/cadastrar"
        component={CadastrarAvaliacao}
        isPrivate
        hasRole={['*']}
      />

      <Route
        path="/avaliacoesOficiais/editar"
        component={EditarAvaliacaoOficial}
        isPrivate
        hasRole={['*']}
      />

      <Route
        path="/avaliacoesOficiais"
        component={AvaliacoesOficiais}
        isPrivate
        hasRole={['SISPROM - CPO']}
      />

      <Route
        path="/avaliacoesFisicas/cadastrar/importar"
        component={CadastrarAvaliacaoFisica}
        isPrivate
        hasRole={['SISPROM - ADM', 'SISPROM - CODIP']}
      />

      <Route
        path="/avaliacoesFisicas/cadastrar"
        component={CadastrarAvaliacaoFisicaIndividual}
        isPrivate
        hasRole={[
          'SISPROM - ADM',
          'SISPROM - CODIP',
          'SISPROM - CPP',
          'SISPROM - CPO',
        ]}
      />

      <Route
        path="/avaliacoesFisicas/editar"
        component={EditarAvaliacaoFisica}
        isPrivate
        hasRole={['SISPROM - ADM', 'SISPROM - CODIP']}
      />

      <Route
        path="/avaliacoesFisicas"
        component={AvaliacoesFisicas}
        isPrivate
        hasRole={['SISPROM - ADM', 'SISPROM - CODIP']}
      />

      <Route
        path="/membrosCPO/cadastrar"
        component={CadastrarMembroCPO}
        isPrivate
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />

      <Route
        path="/julgamentosCPO/editar"
        component={EditarJulgamentosCPO}
        isPrivate
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />

      <Route
        path="/julgamentosCPO"
        component={ListaJulgamentosCPO}
        isPrivate
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />

      <Route
        path="/promocoes/:id_promocao/candidatos/cadastrar/:tipo"
        component={CadastrarCandidato}
        isPrivate
        hasRole={['SISPROM - CPP', 'SISPROM - CPO', 'SISPROM - ADM']}
      />

      <Route
        path="/fifa"
        component={FiFa}
        isPrivate
        hasRole={[
          'SISPROM - CPP',
          'SISPROM - CPO',
          'SISPROM - ADM',
          'SISPROM - OPM',
          'SISPROM - COMANDANTE',
        ]}
      />

      <Route
        path="/fifaCandidato"
        component={FiFa}
        isPrivate
        hasRole={[
          'SISPROM - CPP',
          'SISPROM - CPO',
          'SISPROM - ADM',
          'SISPROM - OPM',
          'SISPROM - COMANDANTE',
        ]}
      />

      <Route
        path="/fifaGeral"
        component={FiFaGeral}
        isPrivate
        hasRole={['*']}
      />
      <Route
        path="/fisfasPoliciais"
        component={ListaFichasOpm}
        isPrivate
        hasRole={[
          'SISPROM - ADM',
          'SISPROM - OPM',
          'SISPROM - CPP',
          'SISPROM - CPO',
          'SISPROM - COMANDANTE',
        ]}
      />

      <Route
        path="/fisfasComandante"
        component={ListaFichasComandante}
        isPrivate
        hasRole={['SISPROM - COMANDANTE', 'SISPROM - ADM']}
      />
      <Route
        path="/documentos"
        component={ListarManuaisDocumentos}
        isPrivate
        hasRole={['*']}
      />
      <Route />
    </Switch>
  );
};

export default Routes;
