import React from 'react';
import { MdArrowDropDown, MdMenu } from 'react-icons/md';
import Countdown, { zeroPad } from 'react-countdown';
import {
  Image,
  HStack,
  useMediaQuery,
  Menu,
  MenuButton,
  Button,
  MenuList,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';
import UserImage from './UserImage';
import DropdownUser from './DropDownUser';
import MenuOpm from './MenuOpm';
import { Divider } from './Divider';
import { useAuth } from '../../../../contexts/auth';
import ImageLogo from '../../../../assets/logo-governo-ceara-50px.png';

interface IHeaderProps {
  handleActiveSideBar(): void;
}

const Header: React.FC<IHeaderProps> = ({
  handleActiveSideBar,
}: IHeaderProps) => {
  const [isLargerCelular] = useMediaQuery(['(min-width: 768px)']);
  const { user, exp, signOut } = useAuth();

  return (
    <Flex
      w="100%"
      height="80px"
      direction="row"
      bg="white"
      color="gray.500"
      justify="space-between"
      align="center"
      boxShadow="lg"
      pl={4}
      fontSize={['sm', 'md', 'lg']}
    >
      <HStack>
        <HStack spacing="48px" pr="8">
          <button type="button" onClick={handleActiveSideBar}>
            <MdMenu size={30} color="#14a64f" />
          </button>
        </HStack>

        {isLargerCelular && (
          <>
            <HStack spacing="16px">
              <Image src={ImageLogo} />
            </HStack>
          </>
        )}
      </HStack>

      {isLargerCelular && <Divider />}
      {[
        'SISPROM - ADM',
        'SISPROM - CPP',
        'SISPROM - CPO',
        'SISPROM - OPM',
        'SISPROM - CODIP',
        'SISPROM - COMANDANTE',
      ].includes(String(user.currentPerfil)) && (
        <>
          <MenuOpm />
          {isLargerCelular && <Divider />}
        </>
      )}

      <Countdown
        date={Number(exp)}
        onComplete={() => signOut()}
        renderer={({ hours, minutes, seconds }) => (
          <>
            {isLargerCelular && (
              <Box color="gray.500">
                {zeroPad(hours)}h:{zeroPad(minutes)}m:{zeroPad(seconds)}s
              </Box>
            )}
          </>
        )}
      />

      {isLargerCelular && <Divider />}

      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              colorScheme="white"
              color="gray.500"
              fontWeight="400"
              _hover={{ bg: { sm: '#fff' } }}
              borderRadius="0"
              h={20}
            >
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <UserImage size="md" />
                {isLargerCelular && (
                  <>
                    <Flex direction="column" marginLeft="8px">
                      <Text fontSize="md" fontWeight="600">
                        {user?.militar
                          ? `${user?.graduacao?.gra_sigla} ${user?.pm_apelido}`
                          : user?.nome}
                      </Text>
                      <Text fontSize="sm" fontWeight="400" marginTop="2px">
                        {user?.militar && user?.opm?.uni_sigla}
                      </Text>
                    </Flex>
                    <MdArrowDropDown size={30} />
                  </>
                )}
              </Flex>
            </MenuButton>
            <MenuList p={0}>
              <DropdownUser />
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
};

export default Header;
