import { Flex, Link } from '@chakra-ui/layout';
import { Img } from '@chakra-ui/image';
import LogoSspds from '../../../../assets/sspds-pm.png';

export function Logo() {
  return (
    <Flex
      direction="row"
      h="80%"
      justify="center"
      align="center"
      w="70%"
      margin="0 auto"
    >
      <Link w="100%" href="https://www.pm.ce.gov.br/">
        <Img
          maxHeight={{ base: '70px', md: '90px' }}
          maxWidth="100%"
          src={LogoSspds}
          alt="logotipo"
        />
      </Link>
    </Flex>
  );
}
