import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type CandidatoCriar = {
  id_promocao: string;
  curso: string;
  fonte: string;
  situacao: string;
  numero_figuracoes?: string;
  id_tipo_impedimento?: number;
  observacao?: string;
};

type ImpedimentoCriar = {
  id_candidato: number;
  status_impedimento: string;
  id_tipo_impedimento: number;
  observacao: string;
};

type ImpedimentoAtualizar = {
  status_impedimento: string;
  id_tipo_impedimento: number;
  id_candidato: number;
  observacao: string;
  id_impedimento: number;
};

type CandidatoAtualizar = {
  id_candidato: number;
  curso?: string;
  fonte?: string;
  situacao?: string;
  numero_figuracoes?: string;
  id_tipo_impedimento?: number;
  observacao?: string;
};

interface ICandidato {
  id_candidato: number;
  id_promocao: string;
  pes_codigo: string;
  curso: string;
  fonte: string;
  classificacao: number;
  situacao: string;
  id_tipo_impedimento: number;
  promovido_bravura: boolean;
  numero_figuracoes?: string;
  observacao: string;
  pessoa: {
    pes_nome: string;
  };
  impedimentos: {
    id_candidato: number;
    id_impedimento: number;
    status_impedimento: string;
    tipo: {
      id_tipo_impedimento: number;
      descricao: string;
      nome: string;
    };
  }[];
}

type CandidatosContextData = {
  candidatoAtual: ICandidato | undefined;
  removerCandidato(id_candidato: number): Promise<any>;
  classificarCandidato(
    id_candidato: number,
    classificacao: number,
    onClose: () => void,
  ): Promise<any>;
  removerImpedimento(
    id_candidato: number,
    id_impedimento: number,
  ): Promise<void>;
  carregarCandidato(id_candidato: number): Promise<void>;
  adicionaCandidato(dados: CandidatoCriar): Promise<void>;
  adicionaImpedimento(dados: ImpedimentoCriar): Promise<void>;
  atualizarImpedimento(dados: ImpedimentoAtualizar): Promise<void>;
  atualizarCandidato(dados: CandidatoAtualizar): Promise<void>;
};

const CandidatosContext = createContext({} as CandidatosContextData);

interface CandidatosProviderProps {
  children: ReactNode;
}

export function CandidatosProvider({ children }: CandidatosProviderProps) {
  const toast = useToast();
  // eslint-disable-next-line
  const [candidatoAtual, setCandidatoAtual] = useState<
    ICandidato | undefined
  >();

  const removerCandidato = useCallback(
    async (id_candidato: number): Promise<any> => {
      try {
        const response = await api.delete(
          `promocoes/candidatos/${id_candidato}`,
        );

        toast({
          title: 'Sucesso!',
          description: 'Candidato removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }
        return undefined;
      }
    },
    [toast],
  );

  const classificarCandidato = useCallback(
    async (
      id_candidato: number,
      classificacao: number,
      onClose: () => void,
    ): Promise<any> => {
      try {
        const response = await api.put(
          `promocoes/classificacao/candidatos/${id_candidato}`,
          { classificacao },
        );

        onClose();

        toast({
          title: 'Sucesso!',
          description: 'Candidato classificado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }
        return undefined;
      }
    },
    [toast],
  );

  const carregarCandidato = useCallback(
    async (id_candidato: number): Promise<void> => {
      try {
        const response = await api.get(`promocoes/candidatos/${id_candidato}`);

        setCandidatoAtual(response.data);
      } catch (error) {}
    },
    [],
  );

  const adicionaCandidato = useCallback(
    async (dados: CandidatoCriar): Promise<any> => {
      try {
        const { data } = await api.post(
          `promocoes/${dados.id_promocao}/candidatos`,
          dados,
        );

        toast({
          title: 'Sucesso!',
          description: 'Candidato adicionado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return data;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
        return undefined;
      }
    },
    [toast],
  );

  const atualizarCandidato = useCallback(
    async (dados: CandidatoAtualizar): Promise<void> => {
      try {
        await api.put(`promocoes/candidatos/${dados.id_candidato}`, dados);
        toast({
          title: 'Sucesso!',
          description: 'Candidato atualizado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const adicionaImpedimento = useCallback(
    async ({ id_candidato, ...rest }: ImpedimentoCriar): Promise<void> => {
      try {
        await api.post(
          `promocoes/candidatos/${id_candidato}/impedimentos`,
          rest,
        );

        const response = await api.get(`promocoes/candidatos/${id_candidato}`);

        setCandidatoAtual(response.data);

        toast({
          title: 'Sucesso!',
          description: 'Impedimento adicionado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const atualizarImpedimento = useCallback(
    async ({
      id_impedimento,
      id_candidato,
      ...rest
    }: ImpedimentoAtualizar): Promise<void> => {
      try {
        await api.put(`impedimentos/${id_impedimento}`, rest);

        const response = await api.get(`promocoes/candidatos/${id_candidato}`);

        setCandidatoAtual(response.data);

        toast({
          title: 'Sucesso!',
          description: 'Impedimento atualizado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const removerImpedimento = useCallback(
    async (id_candidato: number, id_impedimento: number): Promise<void> => {
      try {
        await api.delete(`impedimentos/${id_impedimento}`);

        const response = await api.get(`promocoes/candidatos/${id_candidato}`);

        setCandidatoAtual(response.data);

        toast({
          title: 'Sucesso!',
          description: 'Impedimento removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  return (
    <CandidatosContext.Provider
      value={{
        candidatoAtual,
        classificarCandidato,
        carregarCandidato,
        adicionaImpedimento,
        atualizarImpedimento,
        removerImpedimento,
        removerCandidato,
        adicionaCandidato,
        atualizarCandidato,
      }}
    >
      {children}
    </CandidatosContext.Provider>
  );
}

export const useCandidatos = () => useContext(CandidatosContext);
