import { Box, Text } from '@chakra-ui/layout';
import React from 'react';

import AsyncSelect from 'react-select/async';

const ReactSelect: React.FC<any> = ({
  isDisabled = false,
  value = [],
  loadOptions,
  error,
  placeholder = 'Digite...',
  onChange,
  ...rest
}) => {
  const customStyles2 = {
    container: (base: any) => ({
      ...base,

      transition:
        'border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease',
      '&:hover': {},
    }),
    control: (base: any) => ({
      ...base,
      background: isDisabled ? '#e3e3e3' : '#fff',
      color: isDisabled ? '#999' : '#333',

      fontSize: '14px',
      boxShadow: '#aaa',
      borderColor: error ? '#ff3030' : '#ddd',
      '&:focus': {
        borderColor: error ? '#ff3030' : '#aaa',
        boxShadow: error ? '#ff3030' : '#aaa',
      },
      '&:hover': {
        borderColor: error ? '#ff3030' : '#aaa',
        boxShadow: error ? '#ff3030' : '#aaa',
      },
      '&:active': {
        borderColor: error ? '#ff3030' : '#aaa',
        boxShadow: error ? '#ff3030' : '#aaa',
      },
      '&:focus-within': {
        borderColor: error ? '#ff3030' : '#aaa',
        boxShadow: error ? '#ff3030' : '#aaa',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      background: isDisabled ? '#e3e3e3' : '#fff',
    }),
    multiValue: (base: any) => ({
      ...base,
      background: '#ddd',
      maxWidth: '100px',
    }),
    singleValue: (provided: any, state: any) => ({
      display: state.selectProps.menuIsOpen ? 'none' : 'block',
    }),
  };

  return (
    <Box w="100%">
      <AsyncSelect
        styles={customStyles2}
        placeholder={placeholder}
        value={value}
        // isMulti={isMulti}
        onChange={onChange}
        loadOptions={loadOptions}
        isDisabled={isDisabled}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => 'Sem opções'}
        {...rest}
      />
      {error ? (
        <Text as="span" color="red.300" fontSize="13px" marginTop="2px">
          {error}
        </Text>
      ) : null}
    </Box>
  );
};

export default ReactSelect;
