import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type PromocaoCriar = {
  id_tipo_quadro: string;
  id_tipo_promocao: string;
  ano: string;
  semestre: string;
  data_limite_alteracao_fifa: string;
  data_inicial_recurso_opm: string;
  data_final_recurso_opm: string;
  data_final_recurso_comissao: string;
  data_inicial_recurso_comissao: string;
  data_despacho_comandante: string;
  file: File[];
};

type PromocaoAtualizar = {
  id_promocao: string;
  data_limite_alteracao_fifa: string;
  data_inicial_recurso_opm: string;
  data_final_recurso_opm: string;
  data_final_recurso_comissao: string;
  data_inicial_recurso_comissao: string;
  data_despacho_comandante: string;
};

type Promocao = {
  id_tipo_quadro: string;
  id_tipo_promocao: string;
  ano: string;
  semestre: string;
  data_limite_alteracao_fifa: string;
  data_limite_validacao_fifa: string;
  status: string;
};

type PromocoesContextData = {
  promocoes: any[];
  promocaoAtual: Promocao | undefined;
  adicionaPromocao(dados: PromocaoCriar): Promise<any>;
  atualizarPromocao(dados: PromocaoAtualizar): Promise<any>;
  removerPromocao(id_promocao: string): Promise<any>;
  carregarPromocao(id_promocao: string): Promise<void>;
};

const PromocoesContext = createContext({} as PromocoesContextData);

interface PromocoesProviderProps {
  children: ReactNode;
}

export function PromocoesProvider({ children }: PromocoesProviderProps) {
  const toast = useToast();
  // eslint-disable-next-line
  const [promocoes, setPromocoes] = useState([]);
  const [promocaoAtual, setPromocaoAtual] = useState<Promocao | undefined>(
    undefined,
  );

  const adicionaPromocao = useCallback(
    async (dados: PromocaoCriar): Promise<any> => {
      const formData = new FormData();
      formData.append('id_tipo_promocao', dados.id_tipo_promocao);
      formData.append('id_tipo_quadro', dados.id_tipo_quadro);
      formData.append('ano', new Date(dados.ano).getFullYear().toString());
      formData.append(
        'data_limite_alteracao_fifa',
        dados.data_limite_alteracao_fifa,
      );
      formData.append(
        'data_inicial_recurso_opm',
        dados.data_inicial_recurso_opm,
      );

      formData.append('data_final_recurso_opm', dados.data_final_recurso_opm);
      formData.append(
        'data_inicial_recurso_comissao',
        dados.data_inicial_recurso_comissao,
      );
      formData.append(
        'data_final_recurso_comissao',
        dados.data_final_recurso_comissao,
      );

      formData.append(
        'data_despacho_comandante',
        dados.data_despacho_comandante,
      );
      if (dados.semestre) {
        formData.append('semestre', dados.semestre);
      }

      if (dados.file && dados.file[0]) {
        formData.append('file', dados.file[0]);
      }

      try {
        const response = await api.post(`promocoes/`, formData);

        toast({
          title: 'Sucesso!',
          description: 'Quadro criado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const atualizarPromocao = useCallback(
    async ({ id_promocao, ...rest }: PromocaoAtualizar): Promise<any> => {
      try {
        const response = await api.put(`promocoes/${id_promocao}`, rest);

        toast({
          title: 'Sucesso!',
          description: 'Quadro atualizado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const removerPromocao = useCallback(
    async (id_promocao: string): Promise<any> => {
      try {
        const response = await api.delete(`promocoes/${id_promocao}`);

        toast({
          title: 'Sucesso!',
          description: 'Quadro removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [toast],
  );

  const carregarPromocao = useCallback(
    async (id_promocao: string): Promise<void> => {
      try {
        const { data } = await api.get(`promocoes/${id_promocao}`);

        setPromocaoAtual(data);
      } catch (error) {}
    },
    [],
  );

  return (
    <PromocoesContext.Provider
      value={{
        promocoes,
        promocaoAtual,
        carregarPromocao,
        adicionaPromocao,
        atualizarPromocao,
        removerPromocao,
      }}
    >
      {children}
    </PromocoesContext.Provider>
  );
}

export const usePromocoes = () => useContext(PromocoesContext);
