import axios from 'axios';

// import { signOut } from '../contexts/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

// api.interceptors.response.use(response => {
//   return response;
// }, (error: AxiosError) => {
//   if(error.response && error.response.status === 401){
//       signOut();

//   }
//   return Promise.reject(error);
// })

export default api;
