import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type Membro = {
  id_membro_cpo: number;
  pes_codigo: string;
  membro: number;
  pes_nome: string;
  gra_sigla: string;
};

type MembrosContextData = {
  membros: Membro[];
  removerMembro(id_membro_cpo: number): Promise<any>;
  carregarMembros(): Promise<void>;
};

const MembrosContext = createContext({} as MembrosContextData);

interface MembrosProviderProps {
  children: ReactNode;
}

export function MembrosProvider({ children }: MembrosProviderProps) {
  const toast = useToast();
  // eslint-disable-next-line
  const [membros, setMembros] = useState<Membro[]>([]);

  const removerMembro = useCallback(
    async (id_membro_cpo: number): Promise<any> => {
      try {
        const response = await api.delete(`membros/${id_membro_cpo}`);
        const membrosFiltrados = membros.filter(
          (membro) => membro.id_membro_cpo !== id_membro_cpo,
        );

        setMembros(membrosFiltrados);

        toast({
          title: 'Sucesso!',
          description: 'Membro removido com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        if (error.response) {
          return error.response.data;
        }

        return error;
      }
    },
    [membros, toast],
  );

  const carregarMembros = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get(`membros`);

      setMembros(data);
    } catch (error) {}
  }, []);

  return (
    <MembrosContext.Provider
      value={{
        membros,
        carregarMembros,
        removerMembro,
      }}
    >
      {children}
    </MembrosContext.Provider>
  );
}

export const useMembros = () => useContext(MembrosContext);
