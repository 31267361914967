import { useToast } from '@chakra-ui/toast';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type CriarJulgamento = {
  id_candidato: number;
  membro_1: string;
  membro_1_post_nome: string;
  membro_1_pontos: number;
  membro_1_pontuou: boolean;
  membro_2: string;
  membro_2_post_nome: string;
  membro_2_pontos: number;
  membro_2_pontuou: boolean;
  membro_3: string;
  membro_3_post_nome: string;
  membro_3_pontos: number;
  membro_3_pontuou: boolean;
  membro_4: string;
  membro_4_post_nome: string;
  membro_4_pontos: number;
  membro_4_pontuou: boolean;
  membro_5: string;
  membro_5_post_nome: string;
  membro_5_pontos: number;
  membro_5_pontuou: boolean;
  membro_6: string;
  membro_6_post_nome: string;
  membro_6_pontos: number;
  membro_6_pontuou: boolean;
};

type Julgamento = {
  id_jcpo: number;
  membro_1_post_nome: string;
  membro_2_post_nome: string;
  membro_3_post_nome: string;
  membro_4_post_nome: string;
  membro_5_post_nome: string;
  membro_6_post_nome: string;
};

type JulgamentosContextData = {
  promocaoSelecionada: string | undefined;
  idsCandidatos: number[];
  setIdsCandidatos(ids: number[]): void;
  setPromocaoSelecionada(id_promocao: string): void;
  candidatoSelecionado: number | undefined;
  setCandidatoSelecionado(id_candidato: number): void;
  anoSelecionado: string | undefined;
  setAnoSelecionado(ano: string): void;
  adicionarJulgamento(dados: CriarJulgamento): Promise<Julgamento | undefined>;
  atualizarJulgamento(dados: CriarJulgamento, id_jcpo: number): Promise<any>;
};

const JulgamentosContext = createContext({} as JulgamentosContextData);

interface JulgamentosProviderProps {
  children: ReactNode;
}

export function JulgamentosProvider({ children }: JulgamentosProviderProps) {
  const toast = useToast();
  // eslint-disable-next-line
  const [ promocaoSelecionada, setPromocaoSelecionada] = useState<string>();
  const [candidatoSelecionado, setCandidatoSelecionado] = useState<number>();
  const [anoSelecionado, setAnoSelecionado] = useState<string>();
  const [idsCandidatos, setIdsCandidatos] = useState<number[]>([]);

  const adicionarJulgamento = useCallback(
    async (dados: CriarJulgamento): Promise<Julgamento | undefined> => {
      try {
        const response = await api.post(`julgamentos`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Julgamento realizado com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return response.data;
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });

        return undefined;
      }
    },
    [toast],
  );

  const atualizarJulgamento = useCallback(
    async (dados: CriarJulgamento, id_jcpo: number): Promise<void> => {
      try {
        await api.put(`julgamentos/${id_jcpo}`, dados);

        toast({
          title: 'Sucesso!',
          description: 'Julgamento salvo com sucesso.',
          status: 'success',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response
            ? error.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  return (
    <JulgamentosContext.Provider
      value={{
        promocaoSelecionada,
        setPromocaoSelecionada,
        candidatoSelecionado,
        setCandidatoSelecionado,
        setIdsCandidatos,
        setAnoSelecionado,
        anoSelecionado,
        idsCandidatos,
        adicionarJulgamento,
        atualizarJulgamento,
      }}
    >
      {children}
    </JulgamentosContext.Provider>
  );
}

export const useJulgamentos = () => useContext(JulgamentosContext);
