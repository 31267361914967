import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type Item =
  | 'avaliacoesOficiais'
  | 'cursosInteresse'
  | 'cursosHabilitacao'
  | 'graduacoes'
  | 'pos'
  | 'condecoracoes'
  | 'trabalhos'
  | 'funcoes'
  | 'procedimentos'
  | 'contratos'
  | 'escalas'
  | 'produtividades'
  | 'tafs'
  | 'locais'
  | 'ferimentos'
  | 'punicoes'
  | 'faltas'
  | 'condenacoes'
  | 'desempenhoDisciplinar'
  | 'temposServico';

interface IAtualizarPontuacao {
  item: Item;
  pontosHomologar: number;
  pontosHomologados: number;
}

export interface Pontuacoes {
  temposServico: {
    homologado: number;
    homologar: number;
  };
  graduacoes: {
    homologado: number;
    homologar: number;
  };
  pos: {
    homologado: number;
    homologar: number;
  };
  cursosHabilitacao: {
    homologado: number;
    homologar: number;
  };
  cursosInteresse: {
    homologado: number;
    homologar: number;
  };
  condecoracoes: {
    homologado: number;
    homologar: number;
  };
  trabalhos: {
    homologado: number;
    homologar: number;
  };
  funcoes: {
    homologado: number;
    homologar: number;
  };
  procedimentos: {
    homologado: number;
    homologar: number;
  };
  contratos: {
    homologado: number;
    homologar: number;
  };
  escalas: {
    homologado: number;
    homologar: number;
  };
  produtividades: {
    homologado: number;
    homologar: number;
  };
  tafs: {
    homologado: number;
    homologar: number;
  };
  locais: {
    homologado: number;
    homologar: number;
  };
  ferimentos: {
    homologado: number;
    homologar: number;
  };
  desempenhoDisciplinar: {
    homologado: number;
    homologar: number;
  };
  punicoes: {
    homologado: number;
    homologar: number;
  };
  faltas: {
    homologado: number;
    homologar: number;
  };
  condenacoes: {
    homologado: number;
    homologar: number;
  };
  avaliacoesOficiais: {
    homologado: number;
    homologar: number;
  };
}

const pontuacaoInicial = {
  temposServico: {
    homologado: 0,
    homologar: 0,
  },
  graduacoes: {
    homologado: 0,
    homologar: 0,
  },
  pos: {
    homologado: 0,
    homologar: 0,
  },
  cursosHabilitacao: {
    homologado: 0,
    homologar: 0,
  },
  cursosInteresse: {
    homologado: 0,
    homologar: 0,
  },
  condecoracoes: {
    homologado: 0,
    homologar: 0,
  },
  trabalhos: {
    homologado: 0,
    homologar: 0,
  },
  funcoes: {
    homologado: 0,
    homologar: 0,
  },
  procedimentos: {
    homologado: 0,
    homologar: 0,
  },
  contratos: {
    homologado: 0,
    homologar: 0,
  },
  escalas: {
    homologado: 0,
    homologar: 0,
  },
  produtividades: {
    homologado: 0,
    homologar: 0,
  },
  tafs: {
    homologado: 0,
    homologar: 0,
  },
  locais: {
    homologado: 0,
    homologar: 0,
  },
  ferimentos: {
    homologado: 0,
    homologar: 0,
  },
  desempenhoDisciplinar: {
    homologado: 0,
    homologar: 0,
  },
  punicoes: {
    homologado: 0,
    homologar: 0,
  },
  faltas: {
    homologado: 0,
    homologar: 0,
  },
  condenacoes: {
    homologado: 0,
    homologar: 0,
  },
  avaliacoesOficiais: {
    homologado: 0,
    homologar: 0,
  },
};

type FiFaPontuacaoContextData = {
  pontuacoes: Pontuacoes;
  atualizarPontuacaoFiFa(dados: IAtualizarPontuacao): void;
  resetarPontuacaoFiFa(): void;
};

const FiFaPontuacaoContext = createContext({} as FiFaPontuacaoContextData);

interface FiFaProviderProps {
  children: ReactNode;
}

export function FiFaPontuacaoProvider({ children }: FiFaProviderProps) {
  const [pontuacoes, setPontuacoes] = useState<Pontuacoes>(pontuacaoInicial);

  const atualizarPontuacaoFiFa = useCallback(
    ({
      item,
      pontosHomologar = 0,
      pontosHomologados = 0,
    }: IAtualizarPontuacao) => {
      setPontuacoes({
        ...pontuacoes,
        [item]: {
          homologar: pontosHomologar,
          homologado: pontosHomologados,
        },
      });
    },
    [pontuacoes],
  );

  const resetarPontuacaoFiFa = useCallback(() => {
    setPontuacoes(pontuacaoInicial);
  }, []);

  return (
    <FiFaPontuacaoContext.Provider
      value={{
        atualizarPontuacaoFiFa,
        resetarPontuacaoFiFa,
        pontuacoes,
      }}
    >
      {children}
    </FiFaPontuacaoContext.Provider>
  );
}

export const useFiFaPontuacoes = () => useContext(FiFaPontuacaoContext);
