import React from 'react';
import {
  MdEdit,
  MdDashboard,
  MdSearch,
  MdDescription,
  MdAssignmentTurnedIn,
  MdHelpOutline,
  MdCheck,
} from 'react-icons/md';
import { Box, Flex } from '@chakra-ui/layout';
import { Accordion } from '@chakra-ui/react';
import { Logo } from './Logo';
import MenuItem from './Menu';
import MenuDropdown from './MenuDropdown';
import { Footer } from './Footer';
import { useAuth } from '../../../../contexts/auth';

interface ISideBarProps {
  activated: boolean;
  handleActiveSideBar(): void;
}

const cadastrosCPPItems = [
  { label: 'QUADRO QAG/LQ', to: '/promocoes/cadastrar' },
  { label: 'TAF', to: '/avaliacoesFisicas/cadastrar/' },
];

const cadastrosCPOItems = [
  { label: 'QUADRO QAG/LQ', to: '/promocoes/cadastrar' },
  { label: 'Membros CPO', to: '/membrosCPO/cadastrar' },
  { label: 'TAF', to: '/avaliacoesFisicas/cadastrar/' },
];

const consultasCPOItems = [
  { label: 'QUADROS QAG/LQ', to: '/promocoes' },
  { label: 'Membros CPO', to: '/membrosCPO/cadastrar' },
  { label: 'JCPO', to: '/julgamentosCPO' },
  { label: 'FICHAS', to: '/fisfasPoliciais' },
  { label: 'AVALIAÇÕES OFICIAIS', to: '/avaliacoesOficiais' },
];

const consultasCPPItems = [
  { label: 'QUADROS QAG/LQ', to: '/promocoes' },
  { label: 'FICHAS', to: '/fisfasPoliciais' },
];

const cadastrosCodipItems = [
  {
    label: 'TAFs (PLANILHA)',
    to: '/avaliacoesFisicas/cadastrar/importar',
  },
  { label: 'TAF', to: '/avaliacoesFisicas/cadastrar/' },
];

const consultasCodipItems = [{ label: 'TAFs', to: '/avaliacoesFisicas' }];

const consultasOpmItems = [{ label: 'FICHAS', to: '/fisfasPoliciais' }];

const SideBar: React.FC<ISideBarProps> = ({
  activated,
  handleActiveSideBar,
}) => {
  const { user } = useAuth();

  return (
    <Flex
      minW={activated ? '250px' : '60px'}
      minHeight="100%"
      direction="column"
      boxShadow="4px 0px 9px -4px rgba(0, 0, 0, 0.71)"
      className="Sidebar"
      transition="min-width 0.6s"
    >
      <Box
        display="block"
        h="80px"
        w="100%"
        bg="#fff"
        fontSize="22px"
        fontWeight="800"
        transition="visibility 0.5s"
      >
        <Logo activated={activated} />
      </Box>

      <Flex bg="green.500" direction="column" alignItems="initial" flex="1">
        <Accordion allowToggle>
          {!(user.currentPerfil === 'USUÁRIO PADRÃO') && (
            <MenuItem
              to="/home"
              label="INICIAL"
              icon={MdDashboard}
              activated={activated}
            />
          )}
          {(user.currentPerfil === 'SISPROM - EXTERNO' ||
            (user.oficial &&
              user.currentPerfil &&
              user.currentPerfil === 'USUÁRIO PADRÃO')) && (
            <MenuItem
              to="/avaliacoes/cadastrar"
              label="AVALIAR OFICIAL"
              icon={MdAssignmentTurnedIn}
              activated={activated}
            />
          )}

          {user.currentPerfil === 'USUÁRIO PADRÃO' && (
            <MenuItem
              to="/minhaficha"
              label="MINHA FICHA"
              icon={MdDescription}
              activated={activated}
            />
          )}
          {user.currentPerfil === 'SISPROM - CODIP' && (
            <>
              <MenuDropdown
                label="CADASTRO"
                icon={MdEdit}
                items={cadastrosCodipItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
              <MenuDropdown
                label="CONSULTA"
                icon={MdSearch}
                items={consultasCodipItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
            </>
          )}
          {user.currentPerfil === 'SISPROM - CPP' && (
            <>
              <MenuDropdown
                label="CADASTRO"
                icon={MdEdit}
                items={cadastrosCPPItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
              <MenuDropdown
                label="CONSULTA"
                icon={MdSearch}
                items={consultasCPPItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
            </>
          )}

          {user.currentPerfil === 'SISPROM - CPO' && (
            <>
              <MenuDropdown
                label="CADASTRO"
                icon={MdEdit}
                items={cadastrosCPOItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
              <MenuDropdown
                label="CONSULTA"
                icon={MdSearch}
                items={consultasCPOItems}
                activated={activated}
                handleActiveSideBar={handleActiveSideBar}
              />
            </>
          )}

          {user.currentPerfil === 'SISPROM - OPM' && (
            <MenuDropdown
              label="CONSULTA"
              icon={MdSearch}
              items={consultasOpmItems}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}
          {user.currentPerfil === 'SISPROM - COMANDANTE' && (
            // <MenuDropdown
            //   label="CONSULTA"
            //   icon={MdSearch}
            //   items={consultasComandanteItems}
            //   activated={activated}
            //   handleActiveSideBar={handleActiveSideBar}
            // />
            <MenuItem
              to="/fisfasComandante"
              label="ENVIAR PARA HOMOLOGAÇÃO"
              icon={MdCheck}
              activated={activated}
            />
          )}
          <MenuItem
            to="/documentos"
            label="DOCUMENTOS"
            icon={MdHelpOutline}
            activated={activated}
          />
        </Accordion>
      </Flex>
      <Footer activated={activated} />
    </Flex>
  );
};

export default SideBar;
