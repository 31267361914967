import React, { memo, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { Button } from '@chakra-ui/button';
import { Text } from '@chakra-ui/layout';
import { useAuth } from '../../../../../contexts/auth';
import UserImage from '../UserImage';
import api from '../../../../../services/api';

const DropDown: React.FC = () => {
  const { user, updatePerfil, signOut } = useAuth();

  const handleChangePerfil = (perfil: string): void => {
    updatePerfil(perfil);
    api.defaults.headers.Role = perfil;
  };

  const handleClickSignOut = useCallback(async () => {
    signOut();
  }, [signOut]);

  return (
    <Box
      bg="#fff"
      minW="280px"
      boxShadow="0px 8px 16px 0px rgba(0, 0, 0, 0.2)"
      zIndex={1}
      right={0}
      top="82px"
    >
      <Flex
        width="100%"
        p="16px"
        bg="#14a64f"
        minH="160px"
        direction="column"
        justify="flex-start"
        align="center"
      >
        <UserImage size="lg" />
        <Box fontSize="13px" color="#fff" py="8px" px="0px" textAlign="center">
          <p>{user?.nome}</p>
          <p>{user?.graduacao?.gra_nome}</p>
          <p>Matrícula: {user?.matricula}</p>
          <p>OPM: {user?.opm?.uni_sigla}</p>
        </Box>
      </Flex>
      <Box p="16px">
        <Text as="span" color="#333" mb="8px" fontSize="14px">
          Perfil de acesso:
        </Text>
        <Select
          value={user?.currentPerfil}
          onChange={(e) => handleChangePerfil(e.target.value)}
        >
          {user?.perfis.map((perfil, index) => (
            <option key={index} value={perfil}>
              {perfil}
            </option>
          ))}
        </Select>

        <Button
          colorScheme="red"
          size="sm"
          mt="2"
          variant="solid"
          onClick={() => handleClickSignOut()}
        >
          Sair
        </Button>
      </Box>
    </Box>
  );
};

export default memo(DropDown);
