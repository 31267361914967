import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { IconBaseProps } from 'react-icons';
import { Link } from 'react-router-dom';

interface IProps {
  label: string;
  activated: boolean;
  icon: React.ComponentType<IconBaseProps>;
  to?: any;
  toExternal?: string;
}

const Menu: React.FC<IProps> = ({ label, activated, to, icon: Icon }) => {
  return (
    <Link to={to}>
      <Box>
        <Tooltip
          hasArrow
          label={label}
          isDisabled={activated}
          placement="right"
        >
          <Box
            background="green.500"
            transition="all 0.3s"
            color="white"
            _hover={{ background: 'white', color: 'black' }}
            height="70px"
            w="100%"
            px={`${activated ? '18px' : '16px'}`}
            cursor="pointer"
          >
            <HStack
              display="flex"
              direction="row"
              align="center"
              justify="flex-start"
              height="70px"
            >
              <Box minWidth="25px">
                <Icon size={25} />
              </Box>

              {activated && (
                <Text fontSize="md" visibility="visible" pt="3px">
                  {label.toUpperCase()}
                </Text>
              )}
            </HStack>
          </Box>
        </Tooltip>
      </Box>
    </Link>
  );
};

export default Menu;
