import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import api from '../services/api';

type Avaliacao = {
  id_avaliacao_oficial_fifa: number;
  conhecimento_tecnico: number;
  dedicacao: number;
  desenvoltura: number;
  multiplicacao_conhecimento: number;
  valores_militares: number;
  conhecimento_tecnico_texto: string;
  dedicacao_texto: string;
  desenvoltura_texto: string;
  multiplicacao_conhecimento_texto: string;
  valores_militares_texto: string;
  pontos: number;
  ano: string;
  id_fifa: string;
  avaliador: string;
  id_status_item_fifa: number;
  pes_nome: string;
  pes_codigo: string;
  criado_por: string;
  visualizado_em: string;
  avaliador_externo: string;
};

type AvaliacoesOficiaisContextData = {
  avaliacaoSelecionada: Avaliacao | undefined;
  selecionarAvaliacao(id_avaliacao_oficial_fifa: number): Promise<void>;
};

const AvaliacoesOficiaisContext = createContext(
  {} as AvaliacoesOficiaisContextData,
);

interface AvaliacoesOficiaisProviderProps {
  children: ReactNode;
}

export function AvaliacoesOficiaisProvider({
  children,
}: AvaliacoesOficiaisProviderProps) {
  const [avaliacaoSelecionada, setAvaliacaoSelecionada] = useState<
    Avaliacao | undefined
  >(() => {
    const avaliacao = sessionStorage.getItem('sisprom.avaliacao');

    if (!avaliacao) {
      return undefined;
    }

    return JSON.parse(avaliacao);
  });

  const selecionarAvaliacao = useCallback(
    async (id_avaliacao_oficial_fifa: number): Promise<void> => {
      try {
        const response = await api.get(
          `avaliacoes/${id_avaliacao_oficial_fifa}`,
        );

        sessionStorage.setItem(
          'sisprom.avaliacao',
          JSON.stringify(response.data),
        );
        setAvaliacaoSelecionada(response.data);
      } catch (error) {}
    },
    [],
  );

  return (
    <AvaliacoesOficiaisContext.Provider
      value={{
        avaliacaoSelecionada,
        selecionarAvaliacao,
      }}
    >
      {children}
    </AvaliacoesOficiaisContext.Provider>
  );
}

export const useAvaliacoes = () => useContext(AvaliacoesOficiaisContext);
