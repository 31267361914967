import { AuthProvider } from './auth';
import { PromocoesProvider } from './promocoes';
import { CandidatosProvider } from './candidatos';
import { FiFaProvider } from './fisfas';
import { FiFaPontuacaoProvider } from './fisfasPontuacoes';
import { TafsProvider } from './tafs';
import { MembrosProvider } from './membros';
import { DocumentosProvider } from './documentos';
import { JulgamentosProvider } from './julgamentos';
import { SidebarDrawerProvider } from './SidebarDrawerContext';
import { AvaliacoesOficiaisProvider } from './avaliacoes';

const AppContextProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <PromocoesProvider>
      <CandidatosProvider>
        <FiFaProvider>
          <AvaliacoesOficiaisProvider>
            <FiFaPontuacaoProvider>
              <TafsProvider>
                <DocumentosProvider>
                  <MembrosProvider>
                    <JulgamentosProvider>
                      <SidebarDrawerProvider>{children}</SidebarDrawerProvider>
                    </JulgamentosProvider>
                  </MembrosProvider>
                </DocumentosProvider>
              </TafsProvider>
            </FiFaPontuacaoProvider>
          </AvaliacoesOficiaisProvider>
        </FiFaProvider>
      </CandidatosProvider>
    </PromocoesProvider>
  </AuthProvider>
);

export default AppContextProvider;
