import { useState, useCallback, ReactNode } from 'react';
import { Flex, Box } from '@chakra-ui/layout';
import { useDisclosure, useMediaQuery } from '@chakra-ui/react';

import Header from './Header';

import SideBar from './SideBar';

interface PrivateLayoutProps {
  children: ReactNode;
}

function PrivateLayout({ children }: PrivateLayoutProps) {
  const [activated, setActivated] = useState(true);
  // eslint-disable-next-line
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerCelular] = useMediaQuery(['(min-width: 960px)']);

  const handleActiveSideBar = useCallback(() => {
    if (isLargerCelular) {
      setActivated(!activated);
    } else {
      onOpen();
    }
  }, [activated, onOpen, isLargerCelular]);

  return (
    <>
      <Flex w="100%" direction="row">
        <SideBar
          activated={activated}
          handleActiveSideBar={handleActiveSideBar}
        />

        <Flex width="100%" h="100vh" direction="column">
          <Header handleActiveSideBar={handleActiveSideBar} />
          <Box p="30px 20px 20px">{children}</Box>
        </Flex>
      </Flex>
    </>
  );
}

export { PrivateLayout };

export default PrivateLayout;
