import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './styles/theme';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { DialogUpdate } from './components/dialogUpdate';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// const configuration = {
//   onUpdate: (registration: any) => {
//     if (registration && registration.waiting) {
//       if (
//         // eslint-disable-next-line
//         window.confirm('Uma nova versão do sistema está disponível!  Deseja atualizar agora?')
//       ) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         window.location.reload();
//       }
//     }
//   },
// };

ReactDOM.render(<div />, document.getElementById('alert-update'));

const configuration = {
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          ReactDOM.render(
            <ChakraProvider theme={theme}>
              <DialogUpdate windowTarget={window} />
            </ChakraProvider>,
            document.getElementById('alert-update'),
          );
          // if (

          //   window.confirm(
          //     'Uma nova versão do sistema está disponível!  Deseja atualizar agora?',
          //   )
          // ) {
          //   window.location.reload();
          // }
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};

serviceWorkerRegistration.register(configuration);

reportWebVitals();
