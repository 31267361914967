import { Flex } from '@chakra-ui/layout';
import { ReactNode } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';

interface LayoutPublicoProps {
  children: ReactNode;
}

export default function LayoutPublico({ children }: LayoutPublicoProps) {
  return (
    <Flex w="100vw" h="100vh" direction="column">
      <Header />
      <Flex flex="1" justify="center" align="center" minHeight="300px">
        {children}
      </Flex>
      <Footer />
    </Flex>
  );
}
