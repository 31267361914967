import { Image } from '@chakra-ui/image';
import { Center, Flex } from '@chakra-ui/layout';
import LogoCetic from '../../../../assets/logo-cetic-35px.png';

interface FooterProps {
  activated: boolean;
}

export function Footer({ activated }: FooterProps) {
  return (
    <Flex
      bg="green.500"
      h="120px"
      p={activated ? '40px 40px 20px' : '24px 10px'}
      direction={activated ? 'row' : 'column'}
      justify="space-between"
      transition="padding 0.5s"
    >
      <Center w="100%">
        {activated && <Image src={LogoCetic} alt="logo cetic" />}
      </Center>
    </Flex>
  );
}
