import React from 'react';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

interface IBoxProps {
  windowTarget: Window;
}

const DialogUpdate: React.FC<IBoxProps> = ({ windowTarget }) => {
  const cancelRef = React.useRef<any>();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {}}
      isOpen
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Atualização do Sistema</AlertDialogHeader>

        <AlertDialogBody>
          Existe uma atualização do sistema disponínel. Clique em
          &quot;Sim&quot; para recarregar a página!
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            colorScheme="primary"
            ml={3}
            ref={cancelRef}
            onClick={() => windowTarget.location.reload()}
          >
            Atualizar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DialogUpdate };
