import React, { createContext, useCallback, useState, useContext } from 'react';
import { useToast } from '@chakra-ui/toast';
import { useHistory } from 'react-router';
import axios from 'axios';
import api from '../services/api';

interface IGraduacaoes {
  gra_codigo: number;
  gra_nome: string;
  gra_sigla: string;
}

interface IUser {
  id_usuario: number;
  nome: string;
  matricula: string;
  id_pf: number;
  militar: boolean;
  oficial: boolean;
  perfis: string[];
  pm_apelido?: string;
  pm_numero?: string;
  currentPerfil?: string;
  currentOpm?: { uni_codigo: string; uni_sigla: string };
  verSubunidade?: string;
  graduacao?: { gra_nome: string; gra_sigla: string; gra_codigo: number };
  opm?: { uni_nome: string; uni_sigla: string; uni_codigo: string };
  image?: { data: string; data_alteracao: string };
  graduacoes?: IGraduacaoes[];
}

interface IAuthState {
  token: string;
  exp: string;
  user: IUser;
}

interface ISignInCredentials {
  pes_codigo: string;
  senha: string;
  sis_sigla: string;
}

interface IAuthContextData {
  user: IUser;
  exp: string;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updatePerfil(perfil: string): void;
  updateOpm(opm: { uni_sigla: string; uni_codigo: string }): void;
  updateVerSubunidades(opcao: string): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const history = useHistory();

  const [data, setData] = useState<IAuthState>(() => {
    const token = sessionStorage.getItem('sisprom.token');
    const exp = sessionStorage.getItem('sisprom.token.exp');
    const user = sessionStorage.getItem('sisprom.user');
    const currentPerfil = sessionStorage.getItem('sisprom.current_perfil');

    if (token && user && exp) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const userObject = JSON.parse(user);
      api.defaults.headers.Role = currentPerfil;
      const image = localStorage.getItem(
        `sisprom_${userObject.matricula}_image`,
      );
      if (image) {
        return {
          token,
          exp,
          user: { ...userObject, image: JSON.parse(image) },
        };
      }
      return { token, exp, user: userObject };
    }

    return {} as IAuthState;
  });

  const loadUserImage = useCallback(
    async (user: any, exp: string) => {
      const localImage = localStorage.getItem(
        `sisprom_${user.matricula}_image`,
      );
      let currentImage;
      if (localImage) {
        currentImage = JSON.parse(localImage);
        if (currentImage.data_alteracao === user.data_alteracao) {
          setData({
            ...data,
            exp,
            user: { ...user, image: currentImage },
          });

          return;
        }
      }

      try {
        const responseImage = await api.get(
          `policiais/${user.matricula}/images`,
        );
        const image = responseImage.data;

        const updatedUser = {
          ...user,
          image: { data: image, data_alteracao: user.data_alteracao },
        };

        localStorage.setItem(
          `sisprom_${user.matricula}_image`,
          JSON.stringify({ data: image, data_alteracao: user.data_alteracao }),
        );

        setData({ ...data, exp, user: updatedUser });
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: 'Ocorreu um error ao carregar a imagem do usuário',
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, data],
  );

  const signOut = useCallback(() => {
    sessionStorage.removeItem('sisprom.token');
    sessionStorage.removeItem('sisprom.token.exp');
    sessionStorage.removeItem('sisprom.user');
    sessionStorage.removeItem('sisprom.current_perfil');

    setData({} as IAuthState);
  }, []);

  const signIn = useCallback(
    async ({ pes_codigo, senha, sis_sigla }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL_API_SEG}`,
          { pes_codigo, senha, sis_sigla },
        );
        const {
          token,
          user,
          expiresIn,
        }: {
          token: string;
          user: IUser;
          expiresIn: {
            timestamp: string | undefined;
            ms: string;
          };
        } = response.data;
        const userObject = {
          ...user,
          currentPerfil: user.perfis[0],
          currentOpm: {
            uni_codigo: user.opm ? user.opm.uni_codigo : '',
            uni_sigla: user.opm ? user.opm?.uni_sigla : '',
          },
          verSubunidade: '0',
          oficial: !!(user.graduacao && user.graduacao.gra_codigo < 11),
        };

        const dataExpiresIn = String(Date.now() + Number(expiresIn.ms));

        sessionStorage.setItem('sisprom.token', token);
        sessionStorage.setItem('sisprom.token.exp', dataExpiresIn);
        sessionStorage.setItem('sisprom.user', JSON.stringify(userObject));
        sessionStorage.setItem('sisprom.current_perfil', user.perfis[0]);

        api.defaults.headers.Authorization = `Bearer ${token}`;
        const [perfilInicial] = user.perfis;
        api.defaults.headers.Role = perfilInicial;

        setData({ token, exp: dataExpiresIn, user: userObject });
        loadUserImage(userObject, dataExpiresIn);
        history.push('/dashboard');
      } catch (error) {
        const erro = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: erro.response.data.message,
          status: 'error',
          duration: 15000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [loadUserImage, toast, history],
  );

  const updatePerfil = useCallback(
    (perfil: string) => {
      let userObject;
      if (
        ['SISPROM - ADM', 'SISPROM - CPP', 'SISPROM - CPO'].includes(perfil)
      ) {
        userObject = {
          ...data.user,
          currentPerfil: perfil,
          currentOpm: { uni_codigo: '-1', uni_sigla: 'PMCE' },
        };
      } else {
        userObject = { ...data.user, currentPerfil: perfil };
      }
      setData({ ...data, user: userObject });
      sessionStorage.setItem('sisprom.user', JSON.stringify(userObject));
      sessionStorage.setItem('sisprom.current_perfil', perfil);
    },
    [data],
  );

  const updateOpm = useCallback(
    (opm: { uni_codigo: string; uni_sigla: string }) => {
      const userObject = { ...data.user, currentOpm: opm };
      setData({ ...data, user: userObject });
      sessionStorage.setItem('sisprom.user', JSON.stringify(userObject));
    },
    [data],
  );

  const updateVerSubunidades = useCallback(
    (opcao: string) => {
      const userObject = { ...data.user, verSubunidade: opcao };
      setData({ ...data, user: userObject });

      sessionStorage.setItem('sisprom.user', JSON.stringify(userObject));
    },
    [data],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        exp: data.exp,
        signIn,
        signOut,
        updatePerfil,
        updateOpm,
        updateVerSubunidades,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
