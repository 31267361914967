import React, { Suspense } from 'react';
import {
  Route as ReactDomRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import LayoutPublico from '../components/layout/publico';
import LayoutPrivado from '../components/layout/privado';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  hasRole?: string[];
  isPublicPrivate?: boolean;
  component?: any;
}

const Route: React.FC<IRouteProps> = (
  {
    isPrivate = false,
    isPublicPrivate = false,
    component: Component,
    path,
    hasRole,
  },
  ...rest
) => {
  const { user } = useAuth();

  if (!user && isPrivate) {
    return <Redirect to="/" />;
  }

  if (!!user && !isPrivate && !isPublicPrivate) {
    return <Redirect to="/home" />;
  }

  if (
    !!user &&
    !(
      hasRole &&
      (hasRole.includes(String(user.currentPerfil)) || hasRole.includes('*'))
    )
  ) {
    return <Redirect to="/home" />;
  }

  if (!!user && !Component) {
    return <Redirect to="/home" />;
  }

  if (!user && !Component) {
    return <Redirect to="/" />;
  }

  const Layout = !!user && isPrivate ? LayoutPrivado : LayoutPublico;

  return (
    <ReactDomRoute
      {...rest}
      path={path}
      render={() => (
        <Layout>
          <Suspense fallback={<div>Carregando...</div>}>
            <Component />
          </Suspense>
        </Layout>
      )}
    />
  );
};

export default Route;
